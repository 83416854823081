import { createAction } from '@reduxjs/toolkit';

import UserMaintainerTypes from './userMaintainer.types';

export const fetchUserList = createAction(UserMaintainerTypes.FETCH_USERS);

export const fetchUserListFail = createAction(UserMaintainerTypes.FETCH_USERS_FAIL);

export const fetchUserListSuccess = createAction(UserMaintainerTypes.FETCH_USERS_SUCCESS);

export const setPagination = createAction(UserMaintainerTypes.SET_PAGINATION, (payload) => ({
  payload,
}));

export const setControl = createAction(UserMaintainerTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const clearFilters = createAction(UserMaintainerTypes.CLEAR_FILTERS);

export const updateUser = createAction(UserMaintainerTypes.UPDATE_USER, (payload) => ({
  payload,
}));

export const updateUserFail = createAction(UserMaintainerTypes.UPDATE_USER_FAIL);
export const updateUserSuccess = createAction(UserMaintainerTypes.UPDATE_USER_SUCCESS);

const UserMaintainerActions = {
  fetchUserList,
  fetchUserListFail,
  fetchUserListSuccess,
  setPagination,
  setControl,
  clearFilters,
  updateUser,
  updateUserFail,
  updateUserSuccess,
};

export default UserMaintainerActions;
