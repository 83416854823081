import { Button, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemInStorage } from 'utils/functions';
import { fetchUserList, updateUser } from '../userMaintainer.actions';

function StyledTableCell({ text, sx = {}, align = 'left' }) {
  return (
    <TableCell
      align={align}
      sx={{
        backgroundColor: 'black',
        color: 'white',
        pt: 2,
        pb: 2,
        fontWeight: 'semibold',
        ...sx,
      }}>
      {text}
    </TableCell>
  );
}

// eslint-disable-next-line complexity
function UserTable({ rows }) {
  const dispatch = useDispatch();
  const { pagination, controls } = useSelector((state) => state.userMaintainer);
  const handleDisable = (userId, userName, userType, currentIsEnabled) => {
    const newIsEnabled = !currentIsEnabled;
    dispatch(updateUser({ id: userId, name: userName, type: userType, isEnabled: newIsEnabled }));
    dispatch(fetchUserList({ controls, pagination }));
  };
  const user = getItemInStorage('user');
  return (
    <TableContainer>
      <Table aria-label="request table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ borderTopLeftRadius: 8, pr: 0 }} text="ID de usuario" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Nombre" />
            {user.userType === 'admin' && (
              <>
                <StyledTableCell align="center" sx={{ pr: 0, pl: 0 }} text="Email" />
                <StyledTableCell align="center" sx={{ pr: 0, pl: 0 }} text="Rol" />
                <StyledTableCell
                  align="center"
                  sx={{ borderTopRightRadius: 8, pl: 0, pr: 0 }}
                  text="Acciones"
                />
              </>
            )}
            {user.userType !== 'admin' && (
              <StyledTableCell
                align="center"
                sx={{ borderTopRightRadius: 8, pr: 0, pl: 0 }}
                text="Email"
              />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  No hay usuarios
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              <TableCell align="left" sx={{ pr: 0, pt: 3, pb: 3 }}>
                {row.id}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.name}
              </TableCell>
              <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                {row.email}
              </TableCell>
              {user.userType === 'admin' && (
                <>
                  <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                    {row.type}
                  </TableCell>
                  <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDisable(row.id, row.name, row.type, row.isEnabled)}>
                      {row.isEnabled ? 'Deshabilitar' : 'Habilitar'}
                    </Button>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserTable;
