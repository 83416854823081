import { createReducer } from '@reduxjs/toolkit';

/* Project */
import {
  loginSuccess,
  googleLoginSuccess,
  logoutSuccess,
  samlLoginSuccess,
} from 'content/features/authentication/login/login.actions';
import { removeManyKeysByFilter } from 'utils/functions';
import {
  getStateFromApiSuccess,
  getStateReplaceFromApiSuccess,
  toggleErrorMessage,
  setErrorMessage,
  toggleSuccessMessage,
  toggleConfirmationDialog,
  setSuccessMessage,
  mergeObjectsReducer,
  deleteObjectsReducer,
  mergeDeleteObjectsReducer,
  setPwaPromptEvent,
  setLoader,
} from './app.actions';

const initialState = {
  pwa: {
    promptEvent: null,
  },
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: '',
  error: false,
  successMsg: '',
  success: false,
  signedByGoogle: false,
  confirmDialog: {
    open: false,
    message: '',
    severity: 'info',
    callback: null,
  },
  objects: {
    federatedClients: {},
    tasks: {},
    users: {},
  },
  user: null,
  loading: true,
  loaders: {},
};

const mergeObjects = (state, action) => {
  if (!action.payload.objects) return state.objects;

  const newObjects = {};
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects?.[key] || {}),
    };
  });

  return newObjects;
};

const replaceObjects = (state, action) => {
  if (!action.payload.objects) return state.objects;

  const newObjects = { ...state.objects };

  Object.keys(action.payload.objects).forEach((key) => {
    newObjects[key] = {
      ...(action.payload.objects[key] || {}),
    };
  });

  return newObjects;
};

const deleteObjects = (state, action) => {
  // if (!action.payload.objects) return state.objects;

  const newObjects = { ...state.objects };

  Object.keys(action.payload.ids).forEach((key) => {
    newObjects[key] = removeManyKeysByFilter(newObjects[key], action.payload.ids[key]);
  });

  return newObjects;
};

// eslint-disable-next-line
const mergeDeleteObjects = (state, action) => {
  const newObjects = {};

  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    };
  });

  Object.keys(action.payload.ids).forEach((key) => {
    newObjects[key] = removeManyKeysByFilter(newObjects[key], action.payload.ids[key]);
  });

  return newObjects;
};

const AppReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getStateFromApiSuccess, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.signedByGoogle = action.payload.signedByGoogle;
      state.objects = mergeObjects(state, action);
    })
    .addCase(getStateReplaceFromApiSuccess, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.signedByGoogle = action.payload.signedByGoogle;
      state.objects = replaceObjects(state, action);
    })
    .addCase(toggleErrorMessage, (state, action) => {
      state.error = action.payload.error;
      state.errorMsg = action.payload.msg || '';
    })
    .addCase(setErrorMessage, (state, action) => {
      state.error = true;
      state.errorMsg = action.payload.msg;
    })
    .addCase(toggleSuccessMessage, (state, action) => {
      state.success = action.payload.success;
      state.successMsg = action.payload.msg || '';
    })
    .addCase(setSuccessMessage, (state, action) => {
      state.success = true;
      state.successMsg = action.payload.msg;
    })
    .addCase(toggleConfirmationDialog, (state, action) => {
      state.confirmDialog = {
        ...action.payload,
      };
    })
    .addCase(loginSuccess, (state, action) => {
      state.loadingUser = false;
      state.user = action.payload.user;
      state.signedByGoogle = false;
      state.objects = mergeObjects(state, action);
    })
    .addCase(googleLoginSuccess, (state, action) => {
      state.loadingUser = false;
      state.user = action.payload.user;
      state.signedByGoogle = true;
      state.objects = mergeObjects(state, action);
    })
    .addCase(logoutSuccess, (state) => {
      state.user = null;
      state.signedByGoogle = false;
    })
    .addCase(mergeObjectsReducer, (state, action) => {
      state.objects = mergeObjects(state, action);
    })
    .addCase(deleteObjectsReducer, (state, action) => {
      state.objects = deleteObjects(state, action);
    })
    .addCase(mergeDeleteObjectsReducer, (state, action) => {
      state.objects = mergeDeleteObjects(state, action);
    })
    .addCase(setPwaPromptEvent, (state, action) => {
      state.pwa = {
        promptEvent: action.payload.promptEvent,
      };
    })
    .addCase(setLoader, (state, action) => {
      state.loaders = {
        ...state.loaders,
        [action.payload.id]: action.payload.value,
      };
    })
    .addCase(samlLoginSuccess, (state, action) => {
      state.objects = mergeObjects(state, action);
      state.user = action.payload.user;
    });
});

export default AppReducer;
