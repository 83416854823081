import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Container,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
// Project
import { Logout } from '@mui/icons-material';
import navbarStyles from './navbar.styles';

const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop.png`;
const deloitteMobile = `${window.location.origin}/images/deloitte-mobile.png`;
const NavBar = forwardRef((props, ref) => {
  const { setOpenDrawer, handleLogout, user } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // eslint-disable-next-line complexity
  function sessionOption() {
    if (user) {
      return (
        <>
          <Typography variant="subtitle" sx={navbarStyles.userWelcome} display={isMobile && 'none'}>
            ¡Bienvenido, {user.name}!
          </Typography>
          {!isMobile && (
            <Divider orientation="vertical" variant="middle" flexItem sx={navbarStyles.divider} />
          )}
          <Button
            onClick={() => handleLogout()}
            color="inherit"
            sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px' }}>
            <Logout sx={{ marginRight: 1 }} />
            Cerrar sesión
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          onClick={() => navigate('/login')}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px' }}>
          Iniciar sesión
        </Button>
        <Button
          onClick={() => navigate('/register')}
          color="inherit"
          sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px' }}>
          Registrarse
        </Button>
      </>
    );
  }

  return (
    <AppBar position="static" sx={navbarStyles.navBar} ref={ref}>
      <Container>
        <Toolbar sx={{ px: { sm: 0 } }}>
          {user && (
            <IconButton
              data-testid="toolbar-menu-button"
              onClick={() => setOpenDrawer(true)}
              edge="start"
              sx={navbarStyles.menuButton}
              color="inherit"
              aria-label="menu">
              <MenuIcon />
            </IconButton>
          )}
          <IconButton onClick={() => navigate('/')}>
            <img
              style={{ height: '24px' }}
              src={isMobile ? deloitteMobile : deloitteDesktop}
              alt=""
            />
          </IconButton>
          <Typography variant="h6" sx={navbarStyles.title} />
          {sessionOption()}
        </Toolbar>
      </Container>
    </AppBar>
  );
});

NavBar.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
